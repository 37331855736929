:root {
  --bg-color: #0a0a0a;
  --text-color: #e0e0e0;
  --accent-color: #00ff99;
  --secondary-color: #ff00de;
}
body {
  font-family: 'Orbitron', 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
}
header {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  text-align: center;
  border-bottom: 2px solid var(--accent-color);
}

.subtitle {
  font-size: 1.5em;
  margin-top: 10px;
  color: var(--secondary-color);
}

.content {
  max-width: 800px;
  margin: 40px auto;
  padding: 0 20px;
}
.music-player {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin-top: 40px;
  border: 1px solid var(--secondary-color);
}
.music-player h3 {
  margin-top: 0;
  color: var(--secondary-color);
}
.track-list {
  list-style-type: none;
  padding: 0;
}
.track-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.play-button {
  background: var(--secondary-color);
  color: var(--bg-color);
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
}
footer {
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 20px;
  border-top: 2px solid var(--accent-color);
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}
.animate-pulse {
  animation: pulse 2s infinite;
}
@keyframes neon-flicker {
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    text-shadow:
      -0.2rem -0.2rem 1rem #fff,
      0.2rem 0.2rem 1rem #fff,
      0 0 2rem var(--accent-color),
      0 0 4rem var(--accent-color),
      0 0 6rem var(--accent-color),
      0 0 8rem var(--accent-color),
      0 0 10rem var(--accent-color);
  }
  20%, 24%, 55% {
    text-shadow: none;
  }
}
.neon-text {
  animation: neon-flicker 1.5s infinite alternate;
  color: var(--accent-color);
}
section {
  margin-bottom: 40px;
}